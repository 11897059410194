<template lang="pug">

v-card
  .create-new-modal
    v-card-title
      h2.card-title {{ 'modals.supply.create.title' | translate }}

    v-card-content
      v-date-field(
          :label="$t('base.date')"
          :date-to="this.maxDate"
          v-model="form.created_at")
      v-select.mb-2(
        :label="$t('base.ingredient')"
        :options="ingredientsList"
        v-model="form.ingredient")
      v-select.mb-2(
        :label="$t('base.provider')"
        :options="providersList"
        v-model="form.supplier")
      v-text-field.mb-2(
        :label="$t('modals.supply.create.weight')"
        v-model="form.weight")
      v-text-field.mb-3(
        :label="$t('modals.supply.create.price_of_unit')"
        v-model="form.price")

    v-card-actions
      v-btn(
        :disabled="!isIngredientSelected || !isPriceValid || !isWeightValid || !isSupplierSelected"
        :loading="loading"
        @click="onCreate") {{ 'actions.create' | translate }}

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formateDate } from '@/util/utils'

export default {
  name: 'CreateNewModal',

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    form: {
      ingredient: null,
      supplier: null,
      weight: '',
      price: '',
      created_at: null
    },
    loading: false,
    maxDate: new Date()
  }),

  computed: {
    isIngredientSelected () {
      return this.form.ingredient !== null
    },

    isSupplierSelected () {
      return this.form.supplier !== null
    },

    isPriceValid () {
      return !!this.form.price
    },

    isWeightValid () {
      return !!this.form.weight
    },

    ...mapGetters([
      'ingredientsList',
      'providersList'
    ])
  },

  async mounted () {
    await this.fetchIngredientsList()
    await this.fetchProvidersList()
    this.form.created_at = formateDate(this.maxDate)
  },

  methods: {
    ...mapActions([
      'fetchIngredientsList',
      'fetchProvidersList',
      'addSupply'
    ]),

    async onCreate () {
      this.loading = true
      const payload = {
        ingredient_id: this.form.ingredient,
        weight: this.form.weight,
        price: this.form.price.replace(',', '.'),
        provider_id: this.form.supplier,
        created_at: this.form.created_at
      }
      const { data, error } = await this.addSupply(payload)
      this.loading = false
      if (error) {
        for (const key in data.errors) {
          this.$toasted.show(data.errors[key], {
            position: 'bottom-center',
            duration: 5000
          })
        }
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_variables.scss';

  .create-new-modal {
    &__wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__select {
      width: calc(100% - 5.5rem);
    }

    &__qr {
      display: inline-flex;
      outline: none;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }

    &__tabs-wrap {
      // padding: 1rem 2rem 2rem;
      background-color: $color-gray-light-3;
      border: .1rem solid $color-gray-light-2;
      border-radius: .8rem;
    }

    &__tabs-body {
      padding: 0 2rem 2rem;
    }

    &__tabs-list {
      padding: 1rem 2rem 0;
      display: flex;
      border-bottom: .1rem solid $color-gray-light-2;
    }

    &__tabs-item {
      padding-bottom: .7rem;
      font-size: 1.6rem;
      line-height: 1.8rem;
      color: $color-gray;
      cursor: pointer;
      user-select: none;

      &--active {
        color: $color-black;
        border-bottom: .3rem solid $color-brand;
      }
    }

    &__tabs-item:not(:last-child) {
      margin-right: 2rem;
    }
  }
</style>
